<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button type="primary" class="add" @click="dialogVisible = true"
      v-if="$store.state.powerList.indexOf('distribution:profit:list:add') !== -1">
      添加商品
    </el-button>

    <div class="search_box">
      <span>商品分类：</span>
      <el-select v-model="formData.prodType" clearable @clear="onClear(1)" placeholder="请选择">
        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <div style="display: flex; align-items: center; margin-left: 30px; margin-bottom: 15px">
        <span style="width: 110px">商品名称：</span>
        <el-input v-model="formData.keyword" placeholder="请输入搜索的商品名称"></el-input>
      </div>
      <div style="display: flex; align-items: center; margin-left: 30px; margin-bottom: 15px">
        <span style="width: 80px">店长显示：</span>
        <el-select v-model="formData.indexType" placeholder="请选择">
          <el-option v-for="item in shopUserOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 30px; margin-bottom: 15px">
        <span style="width: 60px">热销套餐：</span>
        <el-select v-model="formData.isTj" placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1" />
          <el-option :key="0" label="否" :value="0" />
          <el-option :key="1" label="是" :value="1" />
        </el-select>
      </div>
      <div style="height: 20px; width: 10px"></div>

      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 60px">浪漫海岸：</span>
        <el-select v-model="formData.isNew" placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1" />
          <el-option :key="0" label="否" :value="0" />
          <el-option :key="1" label="是" :value="1" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 60px">显示：</span>
        <el-select v-model="formData.isShow" placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1" />
          <el-option :key="0" label="否" :value="0" />
          <el-option :key="1" label="是" :value="1" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 60px">状态：</span>
        <el-select v-model="formData.status" placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1" />
          <el-option :key="1" label="启用" :value="1" />
          <el-option :key="0" label="禁用" :value="0" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 60px">御水古：</span>
        <el-select v-model="formData.isHot" placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1" />
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="0" label="否" :value="0" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 100px">店长排序：</span>
        <el-select v-model="formData.sortType" placeholder="请选择">
          <el-option label="全部" :value="0" />
          <el-option label="内部从高到低" :value="1" />
          <el-option label="内部从低到高" :value="2" />
          <el-option label="外部从高到低" :value="3" />
          <el-option label="外部从低到高" :value="4" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 100px">佣金结算方式：</span>
        <el-select v-model="formData.accountWay" placeholder="请选择">
          <el-option label="全部" :value="0" />
          <el-option label="购买佣金立返" :value="1" />
          <el-option label="出游佣金立返" :value="2" />
        </el-select>
      </div>

      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <span style="width: 100px">主推商品：</span>
        <el-select v-model="formData.isPush" placeholder="请选择">
          <el-option label="全部" :value="-1" />
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-left: 10px; margin-bottom: 15px">
        <el-button type="primary" class="btn" @click="getTableList($event, '1')" slot="reference">查询</el-button>
      </div>
    </div>
    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column align="center" type="selection" width="55"> </el-table-column>

      <el-table-column align="center" label="内部排序" width="80">
        <template slot-scope="{ row }">
          <el-input @change="sortChange(row, 1)" v-model="row.nDept" placeholder="请输入内容"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" label="外部排序" width="80">
        <template slot-scope="{ row }">
          <el-input v-model="row.wDept" @change="sortChange(row, 2)" placeholder="请输入内容"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" label="主推排序" width="80">
        <template slot-scope="{ row }">
          <el-input v-model="row.pushDept" @change="sortChange(row, 3)" placeholder="请输入内容"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="prodName" label="产品名称(副标题)"> </el-table-column>
      <el-table-column align="center" prop="prodCate" label="所属分类"> </el-table-column>
      <el-table-column align="center" prop="prodType" label="产品类型" width="110">
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="产品状态" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示" width="110" show-overflow-tooltip>
      </el-table-column>

      <el-table-column align="center" prop="strIsNew" label="是否浪漫海岸" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="strIsHot" label="是否御水古" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="strIsTj" label="是否热销套餐" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="strIsPush" label="是否主推" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="strIndexType" label="店长展示" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="accountWay" label="佣金结算方式" width="110" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" label="操作" width="320">
        <template slot-scope="scope">
          <el-popconfirm v-if="scope.row.status == 0 &&
            $store.state.powerList.indexOf('distribution:profit:list:edit') !== -1
            " title="确认启用该产品吗？" @confirm="toModify(1, [scope.row])">
            <a href=""></a>
            <el-button type="success" size="mini" slot="reference" class="popconfirm">
              启用
            </el-button>
          </el-popconfirm>
          <el-popconfirm v-if="scope.row.status == 1 &&
            $store.state.powerList.indexOf('distribution:profit:list:edit') !== -1
            " title="确认禁用该产品吗？" @confirm="toModify(0, [scope.row])">
            <el-button type="warning" size="mini" slot="reference" class="popconfirm">
              禁用
            </el-button>
          </el-popconfirm>


          <el-dropdown type="primary" style="margin-left: 10px">
            <el-button type="primary" size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-popconfirm v-if="$store.state.powerList.indexOf('distribution:profit:list:delete') !==
                  -1
                  " title="确认删除该产品吗？" @confirm="toDelete([scope.row])">
                  <el-button type="danger" style="margin-left: 10px" size="mini" slot="reference">
                    删除
                  </el-button>
                </el-popconfirm>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button type="primary" size="mini" style="margin-left: 0.625rem" @click="showEdit(scope.row)" v-if="$store.state.powerList.indexOf('distribution:profit:list:edit') !== -1
                  ">
                  分销比例
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button type="primary" size="mini" style="margin-left: 0.625rem" @click="creatCode(scope.row)">
                  生成小程序码
                </el-button>
                <br>
                <el-button type="primary" size="mini" style="margin-left: 0.625rem" @click="toGoodsJournal(scope.row)">
                  查看日志
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="formData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="formData.total">
    </el-pagination>

    <div class="btn_box">
      <el-popconfirm title="确认删除该产品吗？" v-if="$store.state.powerList.indexOf('distribution:profit:list:delete') !== -1"
        @confirm="toDelete(multipleSelection)">
        <el-button type="primary" slot="reference">批量删除</el-button>
      </el-popconfirm>
      <el-popconfirm title="确认启用该产品吗？" v-if="$store.state.powerList.indexOf('distribution:profit:list:edit') !== -1"
        @confirm="toModify(1, multipleSelection)">
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem">批量启用</el-button>
      </el-popconfirm>
      <el-popconfirm title="确认禁用该产品吗？" v-if="$store.state.powerList.indexOf('distribution:profit:list:edit') !== -1"
        @confirm="toModify(0, multipleSelection)">
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem">批量禁用</el-button>
      </el-popconfirm>
      <el-popconfirm title="确认显示该产品吗？" v-if="$store.state.powerList.indexOf('distribution:profit:list:edit') !== -1"
        @confirm="allShow(1, multipleSelection)">
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem">批量显示</el-button>
      </el-popconfirm>
      <el-popconfirm title="确认隐藏该产品吗？" v-if="$store.state.powerList.indexOf('distribution:profit:list:edit') !== -1"
        @confirm="allShow(2, multipleSelection)">
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem">批量隐藏</el-button>
      </el-popconfirm>
      <el-dropdown style="margin-left: 10px" @command="batch">
        <el-button type="primary">
          更多批量操作<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1_1">批量设置浪漫海岸 </el-dropdown-item>
          <el-dropdown-item command="1_2">批量取消浪漫海岸</el-dropdown-item>
          <el-dropdown-item command="2_1">批量御水古</el-dropdown-item>
          <el-dropdown-item command="2_2">批量取消御水古</el-dropdown-item>
          <el-dropdown-item command="3_1">批量热销套餐</el-dropdown-item>
          <el-dropdown-item command="3_2">批量取消热销套餐</el-dropdown-item>
          <el-dropdown-item command="4">批量设置店长展示</el-dropdown-item>
          <el-dropdown-item command="4_1">批量主推</el-dropdown-item>
          <el-dropdown-item command="4_0">批量取消主推</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="选择产品" :visible.sync="dialogVisible" width="70%">
      <product-box v-if="dialogVisible" @addData="onBackData" @close="dialogVisible = false"></product-box>
    </el-dialog>

    <el-dialog title="设置单商品分销比例" :visible.sync="dialogVisible2" width="50%">
      <el-form ref="form" label-width="180px" class="grade_list">
        <div class="grade_item" v-for="(item, index) in gradeList" :key="index">
          <div class="grade_name">等级：{{ item.cateName }}</div>
          <div class="form_item">
            <el-form-item label="	一级分销分润比例 %">
              <el-input-number :min="0" :max="100" :step="0.01" :step-strictly="true" placeholder="请输入要一级分销分润比例"
                v-model="item.firstProfit" />
            </el-form-item>
            <el-form-item label="	二级分销分润比例 %" style="margin-left: 30px">
              <el-input-number :min="0" :max="100" :step="0.01" :step-strictly="true" placeholder="请输入要二级分销分润比例"
                v-model="item.secondProfit" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="tips">可输入0，如果为0 则使用店长等级分销分润</div>
      <el-form ref="form" style="margin-top:10px">
        <el-form-item label="佣金结算方式:" style="margin-bottom:0">
          <!-- 单选 -->
          <el-radio v-model="accountWay" :label="1">购买佣金立返 </el-radio>
          <el-radio v-model="accountWay" :label="2">出游佣金立返</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toAddProduct()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑单商品分销比例" :visible.sync="dialogVisible3" width="50%">
      <el-form ref="form" label-width="180px" class="grade_list">
        <div class="grade_item" v-for="(item, index) in editData.grade" :key="index">
          <div class="grade_name">等级：{{ item.cateName }}</div>
          <div class="form_item">
            <el-form-item label="	一级分销分润比例 %">
              <el-input-number :min="0" :max="100" :step="0.01" :step-strictly="true" placeholder="请输入要一级分销分润比例"
                v-model="item.firstProfit" />
            </el-form-item>
            <el-form-item label="	二级分销分润比例 %">
              <el-input-number :min="0" :max="100" :step="0.01" :step-strictly="true" placeholder="请输入要二级分销分润比例"
                v-model="item.secondProfit" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="tips">可输入0，如果为0 则使用店长等级分销分润</div>
      <el-form ref="form" style="margin-top:10px">
        <el-form-item label="佣金结算方式:" style="margin-bottom:0">
          <!-- 单选 -->
          <el-radio v-model="editData.accountWay" :label="1">购买佣金立返 </el-radio>
          <el-radio v-model="editData.accountWay" :label="2">出游佣金立返</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="toEdit()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input style="width: 300px" v-model.trim="QRcodeSize" placeholder="请输入生成的二维码大小"></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible4" width="30%">
      <!-- 下拉框 -->
      <el-select v-model="shopUserStatus" placeholder="请选择">
        <el-option v-for="item in shopUserOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="submitStatus()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import productBox from "./productBox";
import {
  gradeListApi,
  productListApi,
  productAddApi,
  productModify,
  productDeleteApi,
  checkProfit,
  editProfit,
  isAllShow,
  updataSort,
  barnthSet,
  barnthSetShopUser,

} from "../../api/wanxiaoShop";
import { createEr, createEr2, createEr3, createEr4, createEr5, createEr6, createEr7, createEr8 } from "../../api/erCode";
import {

  createErFood,

} from "../../api/food.js";
export default {
  name: "productList",
  components: {
    productBox,
  },
  data() {
    return {
      //
      dialogVisible4: false,
      QRcodeSize: "",
      codeVisible: false,
      id: "",
      imgUrl: "",
      type: "",
      shopUserStatus: 0,
      shopUserOptions: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "内部店长",
        },
        {
          value: 2,
          label: "外部店长",
        },
      ],
      typeList: [
        { id: null, name: "全部" },
        { id: 1, name: "客房" },
        { id: 2, name: "门票" },
        { id: 3, name: "线路" },
        { id: 4, name: "万旅卡" },
        { id: 5, name: "精选商城" },
        { id: 6, name: "秒杀" },
        { id: 7, name: "拼团" },
        { id: 8, name: "美食" },
        { id: 9, name: "预售" },
        { id: 10, name: "超值礼包" },
      ],
      editData: {
        profitId: null,
        grade: [],
        accountWay: ''
      },
      // indexType  店长显示：0全部 1内部 2外部
      // isTj  热销套餐 -1全部 0否 1 是
      // isNew 浪漫海岸 -1全部 0否 1 是
      // isShow 显示  -1全部 0否 1 是
      // status  状态 -1全部  1 启用 0 禁用
      // 全部下拉框
      formData: {
        prodType: null,
        total: 0,
        pageSize: 10,
        currentPage: 1,
        keyword: "",
        indexType: 0,
        isTj: -1,
        isNew: -1,
        isShow: -1,
        status: -1,
        isHot: -1,
        sortType: 0,
        accountWay: 0,
        isPush: -1
      },
      tableData: [],
      multipleSelection: [],
      gradeList: [], // 分销等级列表
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      selectList: [], // 选择回调
      selectType: null, // 选择类型
      accountWay: 2,//佣金结算方式
      // editaccountWay:''
    };
  },
  created() {
    this.getTableList(); // 获取表格数据
    this.getGradeList(); // 获取等级列表
  },
  methods: {
    toGoodsJournal(row) {
      this.$router.push("/goodsJournal?id=" + row.profitId);
    },
    async allShow(type, list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.profitId;
        })
        .join(",");
      const { data } = await isAllShow({ isShow: type == 1 ? 1 : 0, ids });
      console.log(data);
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    creatCode(row) {
      this.id = row.prodId;
      this.type = row.allType;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    // 修改内部/外部排序
    sortChange(row, type) {
      let params = {
        profitId: row.profitId,
        dept: type == 1 ? Number(row.nDept) :type == 2 ? Number(row.wDept):Number(row.pushDept),
        deptType: type,
      };
      updataSort(params).then((res) => {
        if (res.data.code == 0) {
          this.getTableList();
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    submitStatus() {
      let params = {
        ids: this.multipleSelection
          .map((item) => {
            return item.profitId;
          })
          .join(","),
        status: this.shopUserStatus,
      };
      console.log(params);
      barnthSetShopUser(params).then((res) => {
        if (res.data.code == 0) {
          this.getTableList();
          this.$message.success("设置成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        let params = {
          widthSize: this.QRcodeSize,
        };

        let data;

        // 1 客房 2门票 3 线路 4 万旅卡 5 精选商城 6秒杀 7 拼团 8 美食
        switch (this.type) {
          case 1:
            params.prodId = this.id;
            data = await createEr2(params);

            break;
          case 2:
            params.prodId = this.id;
            data = await createEr3(params);
            break;
          case 3:
            params.prodId = this.id;
            data = await createEr(params);
            break;
          case 4:
            params.prodId = this.id;
            data = await createEr5(params);
            break;
          case 5:
            params.prodId = this.id;
            data = await createEr4(params);
            break;
          case 6:
            params.seckillId = this.id;
            data = await createEr7(params);
            break;
          case 7:
            params.groupBuyId = this.id;
            data = await createEr6(params);
            break;
          case 8:
            params.foodId = this.id;
            data = await createErFood(params);
            break;
          case 9:
            params.presaleId = this.id;
            data = await createEr8(params);
            break;
        }

        this.imgUrl = data.data.msg;
        console.log(data);
      }

      // let fn;
      // switch (this.type) {
      //   case 1:
      //     params.roomId = this.id;

      //     fn = createEr2;
      //     break;
      // }
      // data = await fn(params);
    },
    // 更多批量操作
    batch(e) {
      console.log(e, "e");
      if (this.multipleSelection.length == 0) {
        return this.$message.error("请选择需要操作的商品！");
      }

      if (e == 4) {
        this.dialogVisible4 = true;
      } else {
        const statusList = e.split("_");
        let params = {
          ids: this.multipleSelection
            .map((item) => {
              return item.profitId;
            })
            .join(","),
          nType: statusList[0],
          status: statusList[1],
        };
        // 是否进行操作
        this.$confirm("是否进行操作？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 确定
            barnthSet(params).then((res) => {
              if (res.data.code == 0) {
                this.getTableList();
                this.$message.success("操作成功！");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            // 取消
          });
        console.log(params, "params");
      }
    },
    onSearch(val) {
      // if (val) {
      //   this.typeList = [
      //     { id: 1, name: "客房" },
      //     { id: 2, name: "门票" },
      //     { id: 3, name: "线路" },
      //   ];
      //   return;
      // } else {
      //   this.typeList = [
      //     { id: null, name: "全部" },
      //     { id: 1, name: "客房" },
      //     { id: 2, name: "门票" },
      //     { id: 3, name: "线路" },
      //   ];
      // }
    },
    async getTableList(e, num) {
      console.log(num, "num");
      console.log(this.formData.prodType, "商品");
      console.log(this.formData.keyword, "搜索名称");
      if (this.formData.prodType == null && num == "1" && this.formData.keyword !== "") {
        this.$message.error("请先选择商品的分类");
      } else {
        const { data } = await productListApi(this.formData);
        this.tableData = data.list;
        this.formData.total = data.pagination.total;
        this.formData.pageSize = data.pagination.pageSize;
        this.formData.currentPage = data.pagination.current;
      }
    },
    async getGradeList() {
      const { data } = await gradeListApi();
      let newData = data.data;
      newData.forEach((item) => {
        item.firstProfit = 0;
        item.secondProfit = 0;
      });
      this.gradeList = newData;
      console.log("等级列表", this.gradeList);
    },
    onBackData(arr, type) {
      console.log(arr, type);
      this.selectList = arr;
      this.selectType = type;
      this.gradeList.forEach((item) => {
        item.firstProfit = 0;
        item.secondProfit = 0;
      });
      this.dialogVisible2 = true;
    },
    async toAddProduct() {
      // 判断空字段
      let count = 0;
      this.gradeList.forEach((item) => {
        if (!(item.firstProfit >= 0 && item.secondProfit >= 0)) {
          count++;
        }
      });
      if (count > 0) return this.$message.error("请将信息填写完整！");

      let profitInfo = [...this.selectList];
      profitInfo.forEach((item) => {
        item.prodType = this.selectType;
        item.grade = [...this.gradeList];
      });
      console.log("商品列表：", JSON.stringify(profitInfo));
      const { data } = await productAddApi({ profitInfo, accountWay: this.accountWay });
      if (data.code == 0) {
        this.dialogVisible = false;
        this.dialogVisible2 = false;
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async toModify(status, list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.profitId;
        })
        .join(",");
      const { data } = await productModify({ status, ids });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async toDelete(list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.profitId;
        })
        .join(",");
      const { data } = await productDeleteApi({ ids });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async showEdit(row) {
      this.editData.profitId = row.profitId;
      const { data } = await checkProfit({ profitId: row.profitId });
      console.log(data);
      if (data.code == 0) {
        let newData = {};
        data.data.forEach((item) => {
          newData[`levelId_${item.levelId}`] = item;
          console.log(`levelId_${item.levelId}`, newData[`levelId_${item.levelId}`]);
        });
        let newArr = this.gradeList;
        newArr.forEach((item) => {
          item.firstProfit = 0;
          item.secondProfit = 0;
          if (newData[`levelId_${item.cateId}`]) {
            item.firstProfit = newData[`levelId_${item.cateId}`].firstProfit;
            item.secondProfit = newData[`levelId_${item.cateId}`].secondProfit;
          }
        });
        this.editData.grade = newArr;
        this.editData.accountWay = Number(data.msg)
        this.dialogVisible3 = true;
      } else {
        this.$message.error(data.msg);
      }
    },
    async toEdit() {
      const { data } = await editProfit(this.editData);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.dialogVisible3 = false;
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 隐藏弹窗
    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.productList {

  // /deep/.el-input__inner{
  //   width: 300px !important;
  // }
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .add {
    margin-top: 20px;
  }

  .el-table {
    margin-top: 50px;
  }

  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      margin-left: 20px;
    }
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }

  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }

  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }

  .tips {
    // text-align: right;
    color: orangered;
  }

  .grade_list {
    padding: 0 10px 10px;

    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }

      .form_item {
        display: flex;

        .el-form-item {
          flex: 1;

          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 24px;
  }

  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>
